import React from 'react'
import '../themes/themes.css'
import CroodsChart from '../images/CroodsChart.svg'

const QueueLess = (props) => {
    return (
        <div className="queue-less queue-animate" onClick={props.onClick}>
            <div className="queue-less-img">
                    <img className="register-img" src={CroodsChart} alt="" />
            </div>
            <div className="queue-less-head">
                    <h1 className="register-h1">Queue-less</h1>
                    <h1 className="register-p" >Brands or local business owners can onboard their stores. <br/> Reach out to your users and engage with them digitally.</h1>
            </div>


        </div>
    )
}

export default QueueLess
