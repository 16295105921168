import React from 'react'
import CroodsSafety from '../images/CroodsSafety.svg'

const Safety = (props) => {
    return (
        <div className="safety" onClick={props.onClick}>
            <div className="power-head">
            <h1 className="safety-h1" style={{color:"var(--primary)"}}>Safety is priority!</h1>
            <h1 className="safety-p" >Contactless shopping experience for the post <br /> covid scenario.</h1>

            </div>
            <img className="safety-img" data-aos="fade-up" data-aos-duration="1000" src={CroodsSafety} alt="" />
            
        </div>
    )
}

export default Safety
