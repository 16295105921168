import React from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Slide } from '@material-ui/core';
import BillerX1 from '../images/BillerX1.svg'

class SplashScreen extends React.Component {
    state = {
        redirect: null,
        logoAnimation: null
    };

    componentDidMount() {
        this.setState((prevState) => {
            prevState.logoAnimation = (
               
                <Slide mountOnEnter unmountOnExit direction={"left"} in={true} timeout={1500} children={
                    <div className="splash-div"  >
                            <img className="splash-logo" src={BillerX1}/>
                            <h1 className="splash-h1" style={{color:"#fff"}} >BillerX</h1>
                        </div>
                } />
                      
                        
                        
                    );
            return prevState;
        });
        setTimeout(() => {
            // Make validate API call to direct to login or to home 
            this.setState((prevState) => {
                prevState.redirect = "/main";
                return prevState;
            });
        }, 1500);
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect} />);
        }
        return (
            <div className="splash-screen"
                style={{
                    backgroundColor: '#1D41A6',
                    display: 'flex',
                    justifyContent:'center',
                    alignItems: 'center'
                }}>
                {this.state.logoAnimation}
            </div>
        );
    }
}

export default SplashScreen;
