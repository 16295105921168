import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import '../themes/themes.css'

export class Navbar extends Component {
    render() {
        return (
            <div style={{backgroundColor:this.props.backgroundColor}} className="nav">
                <Link to="/main"><img className="nav-img" style={{top:"1vh"}} src={this.props.img} alt="" /></Link>
              
              <Link to="/about" className="nav-link1" style={{color:this.props.color,textDecoration:"none"}} href="">About</Link>
              <Link to="/contact" className="nav-link2" style={{color:this.props.color,textDecoration:"none"}} href="">Contact</Link>
            </div>
        )
    }
}

export default Navbar
