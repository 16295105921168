import React,{useEffect} from 'react'
import Home from '../views/Home'

const Main = () => {
    useEffect(() => {
        document.title="BillerX:SelfCheckout | No touch commerce"
    }, )
    return (
        <>
            <Home/>
        </>
    )
}

export default Main
