import React,{useState} from 'react'
import Footer from '../Components/Footer'
import '../themes/themes.css'
import Envelope from '../images/Envelope.svg'
import MapPin from '../images/MapPin.svg'
import Facebook from '../images/Facebook.svg'
import Instagram from '../images/Instagram.svg'
import Linkedln1 from '../images/Linkedln1.svg'
import Twitter from '../images/Twitter.svg'

const Contact = () => {

  



  const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e) => {
    e.preventDefault()

    const input=document.getElementById("phone")
    if(input.value.length<10||input.value.length>10){
      document.getElementById("invalid").style.display="block"
          setTimeout(function(){document.getElementById("invalid").style.display="none";
          }, 3000);
          return false;
          
  }

    
   
   const { name,companyname,email, phone,message, } = e.target.elements;
    

    setStatus("Sending...");
    let details = {
      name: name.value,
      companyname: companyname.value,
      email: email.value,
      phone: phone.value,
      message: message.value,
    };
    

    let response = await fetch("http://localhost:5000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });

    


    let result = await response.json();
    alert(result.status)
    setStatus("Submit")
    name.value=""
    email.value=""
    phone.value=""
    companyname.value=""
    message.value=""
    
  };

    




return (
        <>
      <div className="contact">
        <div className="contact-content">
          <h1 className="help" >We’re here to help!</h1>
          <h1 className="tell-us" >Tell us about you, your company and your requirements <br /> and we will deliver an efficient solution.</h1>

          <form className="contact-form" onSubmit={handleSubmit}>
            <input className="input1" type="text" id="name" placeholder="Name" required /> <br />
            <input className="input2" type="text" id="companyname" placeholder="Organization Name" required  />
            <input className="input3" type="email" id="email" placeholder="Email" required /> 
            <input className="input4" type="number" id="phone" name="phone" placeholder="Phone" required  />
            
            <p id="invalid">A phone number should have 10 digits</p>
            <textarea name="" id="message" rows="10" placeholder="How can we Help you?" required ></textarea>
            <button className="submit-btn" type="submit">{status}</button>
            

          </form>

            <h1 className="or">OR</h1> 
            <hr className="or-hr"  />
            <h1 className="connect-with" >Connect with us!</h1>
            <h1 className="drop-us" style={{}}>Drop us an email or contact us at</h1>
            
            <div className="contact-details">
                <div className="email">
                <img className="envelope" src={Envelope} alt="" />
               <h2 className="biller-email" style={{position:"relative",color:"#fff",textDecoration:"underline",fontWeight:"normal"}}>contact@billerx.com</h2>



                </div>
                <svg width="3" height="104" viewBox="0 0 3 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="1.5" y1="-6.55671e-08" x2="1.5" y2="104" stroke="#34C15B" stroke-width="3"/>
                </svg>

                <div className="number">
                <svg style={{position:"relative"}} width="6vw" height="7vh" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5709 20.6317C14.2425 24.0492 17.0127 26.8068 20.4378 28.4629C20.6884 28.5816 20.9656 28.6331 21.2421 28.6121C21.5186 28.5912 21.7849 28.4987 22.0148 28.3436L27.058 24.9806C27.2811 24.8319 27.5377 24.7412 27.8047 24.7166C28.0716 24.6921 28.3405 24.7346 28.5869 24.8402L38.0218 28.8837C38.3423 29.0198 38.6099 29.2565 38.7842 29.5579C38.9586 29.8594 39.0302 30.2093 38.9884 30.555C38.6902 32.8885 37.5516 35.0333 35.7859 36.5878C34.0202 38.1423 31.7484 38.9999 29.396 39C22.1301 39 15.1618 36.1136 10.0241 30.9759C4.88635 25.8382 2 18.8699 2 11.604C2.00012 9.25157 2.85772 6.9798 4.41222 5.21409C5.96671 3.44839 8.1115 2.30985 10.445 2.01164C10.7907 1.9698 11.1406 2.04145 11.4421 2.21578C11.7435 2.39011 11.9801 2.65769 12.1163 2.97819L16.1633 12.4213C16.268 12.6656 16.3107 12.9319 16.2875 13.1966C16.2643 13.4614 16.1761 13.7163 16.0305 13.9386L12.6793 19.0592C12.5268 19.2895 12.4366 19.5555 12.4176 19.8311C12.3986 20.1067 12.4514 20.3826 12.5709 20.6317V20.6317Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <h2 className="contacts" style={{color:"white",fontWeight:"normal"}}>+91 735 011 1331 <br /> +91 866 862 1456 </h2> 
                
                

                </div>
                
                
            </div>
            

            <div className="contact-end">
            <img src={MapPin} style={{width:"5vw",height:"6vh"}} alt="" />

                <p className="address">
                Plot 78/21, Sec no 3, Pune, 411039</p>
            </div>

            </div>

            
            
      </div>
      <Footer image1={Twitter} image2={Facebook} image3={Linkedln1} image4={Instagram}/> 
        </>
    )
}

export default Contact
