import React from 'react'
import CroodsFeedback from '../images/CroodsFeedback.svg'

const Power = (props) => {
    return (
        <div className="power" onClick={props.onClick}>
            <div className="power-head">
            <h1 className="power-h1" style={{color:"var(--primary)"}}>More power to you!</h1>
            <h1 className="power-p" >Do it all with, self-checkout, online ordering <br /> and pickup scheduling.</h1>

            </div>

            <img className="power-img" data-aos="fade-left" data-aos-duration="1000"  src={CroodsFeedback} alt="" />
            
        </div>
    )
}

export default Power
