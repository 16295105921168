import React, { Component } from 'react'
import Power from '../views/Power'
import QuitQueue from '../views/QuitQueue'
import Safety from '../views/Safety'
import ShopBetter from '../views/ShopBetter'
import BillerX1 from '../images/BillerX1.svg'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Facebook1 from '../images/Facebook1.svg'
import Instagram1 from '../images/Instagram1.svg'
import Linkedln2 from '../images/Linkedln2.svg'
import Twitter1 from '../images/Twitter1.svg'




export class Customer extends Component {

componentDidMount(){
document.title = "BillerX: Save time and skip through lines."
}



    render() {
        return (
            <>
            <Navbar backgroundColor="var(--primary)" color="white" img={BillerX1} />
            <QuitQueue/>
            <Power/>
            <Safety/>
            <ShopBetter/>
            
            <Footer backgroundColor="var(--primary)" color="#fff" image1={Twitter1} image2={Facebook1} image3={Linkedln2} image4={Instagram1}/>
                
            </>
        )
    }
}

export default Customer

