import React from 'react'
import CroodsChart2 from '../images/CroodsChart2.svg'

const TwentyFour = (props) => {
    return (
        <div className="queue-less" onClick={props.onClick}>
            <div className="queue-less-img">
             <img data-aos="flip-up" data-aos-duration="1000" className="register-img" src={CroodsChart2} alt="" />

            </div>
            <div className="queue-less-head">
            <h1 className="register-h1" >24x7 & Everywhere!</h1>
            <h1 className="register-p" >An omni-channel presence that cannot be ignored, cater <br /> to all your customers all day, every day, digitally!</h1>

            </div>


        </div>
        
    )
}

export default TwentyFour
