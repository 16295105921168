import React from 'react'
import CroodsChart1 from '../images/CroodsChart1.svg'

const DataisEverything = (props) => {
    return (
        <div className="queue-less"  onClick={props.onClick}>
            <div className="data-img">
                <img data-aos="flip-left" data-aos-duration="1000" className="register-img" src={CroodsChart1} alt="" />
            </div>
            <div className="data-head">
            <h1 className="register-h1">Data is everything!</h1>
            <h1 className="register-p" >Easy marketing to audience about the store’s offerings <br /> and sales. <br /> Get Customer-Item interaction analytics to improve sales.</h1>

            </div>
            
        </div>
    )
}

export default DataisEverything
