import React, { Component } from 'react'
import QueueLess from '../views/QueueLess'
import DataisEverything from '../views/DataisEverything'
import TwentyFour from '../views/TwentyFour'
import BillerX from '../images/BillerX.svg'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Facebook from '../images/Facebook.svg'
import Instagram from '../images/Instagram.svg'
import Linkedln1 from '../images/Linkedln1.svg'
import Twitter from '../images/Twitter.svg'





export class Retailer extends Component {
    componentDidMount(){
        document.title="BillerX: Increase in store efficiency with selfcheckout."
      }

   


    render() {
        return (
            <>
            <Navbar backgroundColor="white" color="var(--primary)" img={BillerX}/>
            <QueueLess/>
            <DataisEverything/>
            <TwentyFour/>
            <Footer image1={Twitter} image2={Facebook} image3={Linkedln1} image4={Instagram}/> 
            </>
            
        )
    }
}

export default Retailer

