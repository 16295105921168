
import './App.css';
import Customer from './Routes/Customer';
import Main from './Routes/Main';
import { Switch , Route, Redirect} from 'react-router-dom';
import Retailer from './Routes/Retailer';

import Home from './views/Home'
import AboutPage from './Routes/AboutPage';
import ContactPage from './Routes/ContactPage';
import SplashScreen from './views/SplashScreen';


function App() {
  return (
    <>
     <Switch>
     <Route exact path="/" component={SplashScreen} />
    <Route exact path="/main" component={Main} />
    <Route exact path="/customer" component ={Customer} />
    <Route exact path="/retailer" component={Retailer} />
    <Route exact path="/about" component={AboutPage} />
    <Route exact path="/contact" component={ContactPage} />
    
    <Redirect to ="/"/>
  

     </Switch>
     
     

    </>
  );
}

export default App;
