import React, { useEffect } from 'react'
import About from '../views/About'
import Navbar from '../Components/Navbar'
import '../themes/themes.css'
import BillerX1 from '../images/BillerX1.svg'

const AboutPage = () => {

    useEffect(() => {
        document.title="BillerX: Meet the team."
    }, )

    
    return (
        <>
            <Navbar backgroundColor="var(--primary)" color="white" img={BillerX1} />
            <About/>
        </>
    )
}

export default AboutPage
