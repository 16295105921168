import React from 'react'
import '../themes/themes.css'
import Croods1 from '../images/Croods1.svg'
import Croods2 from '../images/Croods2.svg'
import Croods3 from '../images/Croods3.svg'
import Croods4 from '../images/Croods4.svg'


const QuitQueue = (props) => {
    return (
        <div className="quit-queue customer-animation" onClick={props.onClick}>
            <div className="queue-head">
                <h1 className="queue-h1" style={{color:"var(--primary)"}}>Quit the queues!</h1>
                <h1 className="queue-p">No more waiting in the queue. <br /> Shop at your own pace.</h1>
            </div>

            <div className="queue-images">
                <img className="queue-img img-1"  src={Croods1} alt="" />
                <img className="queue-img img-2"  src={Croods2} alt="" />
                <img className="queue-img img-3"  src={Croods3} alt="" />
                <img className="queue-img img-4"  src={Croods4} alt="" />

            </div>

            
        </div>
    )
}

export default QuitQueue

