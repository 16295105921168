
import Footer from '../Components/Footer'
import Amey from '../images/Amey.svg'
import Chinmay from '../images/Chinmay.svg'
import Emmanuel from '../images/Emmu.svg'
import Linkedln from '../images/Linkedln.svg'
import '../themes/themes.css'
import Facebook1 from '../images/Facebook1.svg'
import Instagram1 from '../images/Instagram1.svg'
import Linkedln2 from '../images/Linkedln2.svg'
import Twitter1 from '../images/Twitter1.svg'

const About = () => {

    return (
        <>
        
    <div className="about">
        
            <div className="about-content">
                <h1 className="about-us" >A little about us.</h1>
                <h1 className="small-group" >We are small group of individuals passionate and driven  to solve problems collectively and revolutionize the shopping experience for everyone.</h1>

        <div className="about-profiles">
            
            <div className="profile1">
                 <img className="profile-imgs" src={Chinmay} alt="" />
                 <div className="profile-content" data-aos="slide-left" data-aos-duration="1000">
                 <nobr>   <h2>Chinmay Raut, (CEO)</h2>  
                 <a href="https://www.linkedin.com/in/chinmay-r-49b60216b/" target="_blank" rel="noopener noreferrer"><img src={Linkedln} className="profile-urls" alt="" /></a>
                 </nobr>
                     <p>Focuses on Product Development and Lead generation. Expertise <br /> in strategizing for company growth and managing team.</p>
                    
                 </div>
            </div>

            <div className="profile2">
                <img className="profile-imgs" src={Emmanuel} alt="" />
                <div className="profile-content" data-aos="slide-left" data-aos-duration="1000">
                <nobr>
                <h2>Emmanuel D’Souza, (CTO)</h2>
                <a href="https://www.linkedin.com/in/emmanuel-dsouza-9667b1189/" target="_blank" rel="noopener noreferrer"><img src={Linkedln} className="profile-urls" alt="" /></a>
                </nobr>     
                <p>Leads the tech teams and in-charge of Product Engineering.</p>
                     

                </div>
            </div>
            
            <div className="profile3">
                <img className="profile-imgs" src={Amey} alt="" />
                <div className="profile-content" data-aos="slide-left" data-aos-duration="1000">
                <nobr>
                    <h2>Amey Rithe, (CSO)</h2>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/amey-rithe-159515166/" ><img src={Linkedln} className="profile-urls" alt="" /></a>
                
                 </nobr>    
                     <p>Focuses on B2B Sales and Marketing for product launch and <br /> releases. Leads client acquisition team.</p>
                     

                 </div>
                
            </div>


        </div>
            
            
            
    </div>
            
    
        </div>
        <Footer color="#fff" backgroundColor="var(--primary)" image1={Twitter1} image2={Facebook1} image3={Linkedln2} image4={Instagram1}/>
        
        
        </>
    )
}

export default About
