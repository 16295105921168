import React, { Component } from 'react'


export class Footer extends Component {
    render() {
        return (
            <div className="footer" style={{backgroundColor:this.props.backgroundColor,color:this.props.color}}>
            <div className="footer-content">
             <h3 className="footer-h3" style={{fontWeight:"normal"}}>© 2021 Frozen Lake Technologies</h3>
             <p className="footer-p">All rights reserved. | Privacy Policy |Terms of Use | LegalSite Map</p> 

            </div>
            <div className="footer-icons">
                <a href="https://twitter.com/billerxapp" target="_blank" rel="noopener noreferrer"><img className="footer-imgs" src={this.props.image1} alt="" /></a>
                <a href="https://www.facebook.com/billerx" target="_blank" rel="noopener noreferrer"><img className="footer-imgs" src={this.props.image2} alt="" /></a>
                <a href="https://www.linkedin.com/company/billerxapp" target="_blank" rel="noopener noreferrer"><img className="footer-imgs" src={this.props.image3} alt="" /></a>
                <a href="https://www.instagram.com/billerxapp/" target="_blank" rel="noopener noreferrer"><img className="footer-imgs" src={this.props.image4} alt="" /></a>

            
            


            </div>
            
        </div>
        )
    }
}

export default Footer

