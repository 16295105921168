import React from 'react'
import CroodsAvatar from '../images/CroodsAvatar.svg'

const ShopBetter = (props) => {
    return (
        <div className="safety" onClick={props.onClick}>
            <div className="power-head">
            <h1 className="safety-h1" style={{color:"var(--primary)"}}>Shop better!</h1>
            <h1 className="safety-p shop-p">Experience the revolutionary, self-checkout <span>now.</span> </h1>

            </div>
            <img className="shop-img"  src={CroodsAvatar} alt="" />
           <a href="http://www.jayoo.in" target="_blank" rel="noopener noreferrer"><button data-aos="flip-left" data-aos-delay="300" className="shop-btn">Try Now</button></a> 
        </div>
    )
}

export default ShopBetter
