import React, { useEffect } from 'react'
import Contact from '../views/Contact'
import Navbar from '../Components/Navbar'
import BillerX from '../images/BillerX.svg'
import {useHistory,Redirect} from 'react-router-dom'

const ContactPage = () => {
    const history=useHistory()
 
    useEffect(() => {
        document.title="BillerX:SelfCheckout | No touch commerce"
        

    }, )

    

    return (
        <>
            <Navbar backgroundColor="white" color="var(--primary)" img={BillerX}/>
            <Contact/>
        </>
    )
}

export default ContactPage
