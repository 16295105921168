import React from 'react'
import { Link } from 'react-router-dom'


const Home = () => {
const cust=()=>{
 document.getElementById("who").innerHTML="Are you a?"
 document.getElementById("line2").style.transition="0.5s"
 document.getElementById("line1").style.transition="0s"
 document.getElementById("who").style.color="var(--success)"
 document.getElementById("line1").style.visibility="hidden"
 document.getElementById("line2").style.marginRight="20vw"
 document.getElementById("line2").style.width="35vw"
 
}

const cust1=()=>{
    document.getElementById("who").innerHTML="Are you a?"
    document.getElementById("who").style.color="var(--white)"
    document.getElementById("line1").style.visibility="visible"
    document.getElementById("line2").style.marginRight="0vw"
    document.getElementById("line2").style.width="15vw"
   }
const ret=()=>{
    document.getElementById("who").innerHTML="Are you a?"
    document.getElementById("line1").style.transition="0.5s"
    document.getElementById("line2").style.transition="0s"
    document.getElementById("who").style.color="var(--success)"
    document.getElementById("line2").style.visibility="hidden"
    document.getElementById("line1").style.marginLeft="20vw"
 document.getElementById("line1").style.width="35vw"
 
}
const ret1=()=>{
    document.getElementById("who").innerHTML="Are you a?"
    document.getElementById("who").style.color="var(--white)"
    document.getElementById("line2").style.visibility="visible"
    document.getElementById("line1").style.marginLeft="0vw"
    document.getElementById("line1").style.width="15vw"
}

    return (
        <div className="main">
            
            
        <div className="head" >
            <h1 className="main-h1" style={{color:"white"}}>Welcome to a brand <span>new shopping </span>  <span> experience!</span> </h1>
        </div>
        <div className="main-question">
        <hr style={{color:"#fff",borderColor:"#fff",backgroundColor:"#fff",position:"relative"}} id="line1" className="line1">
        
        </hr>

            <h1 id="who" style={{color:"white",fontWeight:"normal"}}>Are you a?</h1>

        <hr style={{color:"#fff",borderColor:"#fff",backgroundColor:"#fff",position:"relative"}}  id="line2" className="line2" >
        
        </hr>
            
        </div>

        <div className="main-buttons">
            <Link to="/customer"><button id="customer-btn" onMouseLeave={cust1} onMouseOver={cust}>Customer</button></Link >
            <Link  to="/retailer"><button id="retailer-btn" onMouseLeave={ret1} onMouseEnter={ret}>Retailers</button></Link >

        </div>
        
    </div>
    )
}

export default Home


